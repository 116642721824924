import React from 'react';
import Layout from '../Layout/Layout';
import { SkipToContent } from '@entur/a11y';
import '@entur/loader/dist/styles.css';
import '@entur/button/dist/styles.css';
import '@entur/layout/dist/styles.css';
import '@entur/typography/dist/styles.css';
import '@entur/menu/dist/styles.css';
import '@entur/a11y/dist/styles.css';
import '@entur/expand/dist/styles.css';
import '@entur/icons/dist/styles.css';
import '@entur/form/dist/styles.css';
import '@entur/expand/dist/styles.css';
import '@entur/tooltip/dist/styles.css';
import '@entur/chip/dist/styles.css';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <SkipToContent mainId={'content'}>Gå til hovedinnhold</SkipToContent>
        <Layout />
      </QueryClientProvider>
    </div>
  );
}

export default App;

import React from 'react';
import { NavigationCard } from '@entur/layout';
import {
  DestinationIcon,
  EnvironmentIcon,
  SendIcon
} from '@entur/icons';
import { Heading2, Paragraph } from '@entur/typography';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

interface Props {
  title: string;
  subtitle: string;
}

function CardSection(props: Props) {
  return (
    <div className={styles.about}>
      <Heading2>{props.title}</Heading2>
      <Paragraph>{props.subtitle}</Paragraph>
      <div className={styles.cards}>
        <NavigationCard
          title={'Pilot med Digdir'}
          titleIcon={<SendIcon />}
          as={Link}
          to={'/ressurser#digdir'}
        >
          Videreutvikling av nasjonale fellesløsninger
        </NavigationCard>
        <NavigationCard
          title={'Reisemønster på tvers'}
          titleIcon={<DestinationIcon />}
          as={Link}
          to={'/ressurser#mobilitetsdata'}
        >
          Hvordan er reisemønsteret til en vanlig nordmann?
        </NavigationCard>
        <NavigationCard
          title={'Felles utslippsberegning for samferdsel'}
          titleIcon={<EnvironmentIcon />}
          as={Link}
          to={'/ressurser#co2e'}
        >
          Standardisert beregning av CO2e-utslipp i kollektivsektoren
        </NavigationCard>
      </div>
    </div>
  );
}

export default CardSection;

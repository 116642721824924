import React from 'react';
import styles from './styles.module.scss';
import moter from '../../assets/images/moter.png';
import fp1 from '../../assets/images/forprosjekt1.png';
import fp2 from '../../assets/images/forprosjekt2.png';
import ob from '../../assets/images/oppdragsbrev.png';

interface TimelineElementProps {
  date: string;
  desc: string;
  img: string;
}

function Timeline() {
  const element = (props: TimelineElementProps) => {
    return (
      <div className={styles.timelineElement}>
        <img className={styles.page} src={props.img} alt="" />
        <span className={styles.dato}>{props.date}</span>
        <span className={styles.linje} />
        <span className={styles.point} />
        <span className={styles.ball} />
        <span className={styles.desc}>{props.desc}</span>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {element({ date: 'MAI 2020', desc: 'Innledende møter', img: moter })}
      {element({ date: 'HØSTEN 2020', desc: 'Forprosjekt 1', img: fp1 })}
      {element({ date: 'NOVEMBER 2021', desc: 'Forprosjekt 2', img: fp2 })}
      {element({
        date: 'FEBRUAR 2022',
        desc: 'Oppdragsbrev og prosjektstart',
        img: ob
      })}
    </div>
  );
}

export default Timeline;

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function Scroller() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!hash.startsWith('#')) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [pathname, hash]);

  return null;
}

export default Scroller;

import React from 'react';
import MediaQuery from 'react-responsive';
import { Heading3, Link, Paragraph } from '@entur/typography';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import styles from './styles.module.scss';

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <MediaQuery minWidth={992}>
          <div className={styles.flavour}>
            <Paragraph className={styles.flavourText}>
              Tverrsektorielt datasamarbeid jobber for å få til et velfungerende
              og kontinuerlig utviklende datasamarbeid i samferdselssektoren
            </Paragraph>
            <div className={styles.flavourLogo}>
              <Logo />
            </div>
          </div>
        </MediaQuery>
        <div className={styles.linkList}>
          <Heading3>Sidestruktur</Heading3>
          <div>
            <Link href={'/'}>Forside</Link>
          </div>
          <div>
            <Link href={'omoss'}>Hvem er vi?</Link>
          </div>
          <div>
            <Link href={'ressurser'}>Hva gjør vi?</Link>
          </div>
          <div>
            <Link href={'aktuelt'}>Aktuelt</Link>
          </div>
          <div>
            <Link href={'kontakt'}>Kontakt oss</Link>
          </div>
          <div className={styles.erklaering}>
            <Link
              href={
                'https://uustatus.no/nb/erklaringer/publisert/ab2bce2b-35c6-43e9-b3e4-ad8f03fd984c'
              }
              target={'_blank'}
            >
              Tilgjengelighetserklæring
            </Link>
          </div>
        </div>
        <div className={styles.linkList}>
          <Heading3>Kontakt</Heading3>
          <div>
            <Link
              href={'https://offentlig-paas-no.slack.com/archives/C050R0TRU2Z'}
              target="_blank"
              rel="noreferrer"
            >
              Kontakt oss på Slack
            </Link>
          </div>
          <div>
            <Link href={'mailto:kontakt@samferdselsdata.no'} target={'_blank'}>
              Send oss en mail
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

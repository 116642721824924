import React from 'react';
import felleslosning from '../../assets/felleslosning.png';
import bruksomrade from '../../assets/bruksomrade.png';
import juridisk from '../../assets/juridisk.png';
import organisering from '../../assets/organisering.svg';

import styles from './styles.module.scss';
import { useMediaQuery } from 'react-responsive';

function Samarbeidsstruktur() {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 767px)' });

  const underboks = (text: string, icon: string) => {
    return (
      <div className={styles.underboks}>
        {text}
        {!isMobileScreen && <img src={icon} alt="" />}
      </div>
    );
  };

  if (!isMobileScreen) {
    return <img src={organisering} alt="" className={styles.organisering} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.toppboks}>Ledelse</div>
      <div className={styles.topplinje}>
        <span />
      </div>
      <div className={styles.linje}>
        <span />
      </div>
      <div className={styles.bokser}>
        <div className={styles.boks}>{underboks('Teknisk', felleslosning)}</div>
        <div className={styles.boks}>{underboks('Verdicase', bruksomrade)}</div>
        <div className={styles.boks}>{underboks('Juridisk', juridisk)}</div>
      </div>
    </div>
  );
}

export default Samarbeidsstruktur;

import React, {useEffect, useState} from 'react';
import {
  Heading1,
  Heading2,
  LeadParagraph,
  Link
} from '@entur/typography';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as AktueltSvg } from '../../assets/decoration/aktuelt.svg';
import CustomSection from '../CustomSection/CustomSection';
import AktueltBoks from '../AktueltBoks/AktueltBoks';
import moment from 'moment';
import events from '../../events.json';
import styles from './styles.module.scss';

interface Event {
  tittel: string;
  link?: string;
  beskrivelse: string;
  dato: string;
  tid?: string;
  sted?: string;
  fokus?: string;
  begrenset: boolean;
  external: boolean;
}

function AktueltSide() {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const [ current_events, setCurrentEvents ] = useState<Event[]>([]);
  const [ prev_events, setPrevEvents ] = useState<Event[]>([]);

  useEffect(() => {
    const now = new Date();
    const curr: Event[] = [];
    const prev: Event[] = [];
    events.forEach(event => {
      const date = moment(event.dato, "DD.MM.YYYY").add("1", "day").toDate();
      date > now ? curr.push(event) : prev.push(event);
    });

    const sortByDate = (a: Event, b: Event): number => {
      const date_a = moment(a.dato, "DD.MM.YYYY").valueOf();
      const date_b = moment(b.dato, "DD.MM.YYYY").valueOf();
      return date_b - date_a;
    };

    curr.sort(sortByDate).reverse();
    prev.sort(sortByDate);

    setCurrentEvents(curr);
    setPrevEvents(prev);
  }, []);

  return (
    <>
      <Helmet>
        <title>Aktuelt | Samferdselsdata</title>
      </Helmet>
      <CustomSection className={styles.titleContainer}>
          <div>
            <Heading1>Aktuelt</Heading1>
            <LeadParagraph>
              Samarbeidet ønsker å tilrettelegge for erfaringsdeling og øke
              fokus på verdien av bruk og deling av data. derfor både deltar vi
              på og arrangerer en rekke møteplasser. Dersom du ønsker et varsel
              ved nye arrangementer kan du registrere deg {" "}
              <Link
                target={"_blank"}
                rel={"noreferrer"}
                href={"https://forms.office.com/Pages/ResponsePage.aspx?id=vpbBMGsQ0ESd9caS_gjTPdKXH4aMfb9FncWD75fW3h1UMzg2UktYM05GSzNNUkRETThSSVdRVENJTi4u"}>
                her
              </Link>.
            </LeadParagraph>
          </div>
          {!isMobileScreen && <AktueltSvg />}
      </CustomSection>
      <CustomSection className={styles.kommende} id={'arrangementer'}>
        <Heading2>Kommende arrangementer</Heading2>
        <div className={styles.cards}>
          {current_events.map((event: Event) => (
            <AktueltBoks
              key={event.tittel}
              to={event.link}
              title={event.tittel}
              open={!event.begrenset}
              desc={event.beskrivelse}
              target={event.fokus}
              date={event.dato}
              time={event.tid}
              place={event.sted}
              external={event.external}
            />
          ))}
        </div>
      </CustomSection>
      <CustomSection className={styles.tidligere}>
        <Heading2>Tidligere arrangementer</Heading2>
        <div className={styles.cards}>
          {prev_events.map((event: Event) => (
            <AktueltBoks
              key={event.tittel}
              to={event.link}
              title={event.tittel}
              open={!event.begrenset}
              desc={event.beskrivelse}
              target={event.fokus}
              date={event.dato}
              time={event.tid}
              place={event.sted}
              external={event.external}
            />
          ))}
        </div>
      </CustomSection>
    </>
  );
}

export default AktueltSide;

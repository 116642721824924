import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Link, useLocation } from 'react-router-dom';
import { OverflowMenu, OverflowMenuLink, TopNavigationItem } from '@entur/menu';
import { useMediaQuery } from 'react-responsive';
import { IconButton } from '@entur/button';
import { MenuIcon } from '@entur/icons';
import { VisuallyHidden } from '@entur/a11y';
import styles from './styles.module.scss';

function Header() {
  const location = useLocation();
  const isHandheldDevice = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <header className={styles.header}>
      <Link className={styles.headerIcon} to={'/'}>
        <Logo />
        <VisuallyHidden>Forside</VisuallyHidden>
      </Link>
      <div className={styles.headerList}>
        {isHandheldDevice ? (
          <OverflowMenu
            position={'left'}
            button={
              <IconButton>
                <MenuIcon />
              </IconButton>
            }
          >
            <OverflowMenuLink onSelect={() => {}} href={'/'}>
              Forside
            </OverflowMenuLink>
            <OverflowMenuLink onSelect={() => {}} href={'omoss'}>
              Hvem er vi?
            </OverflowMenuLink>
            <OverflowMenuLink onSelect={() => {}} href={'ressurser'}>
              Hva gjør vi?
            </OverflowMenuLink>
            <OverflowMenuLink onSelect={() => {}} href={'aktuelt'}>
              Aktuelt
            </OverflowMenuLink>
            <OverflowMenuLink onSelect={() => {}} href={'kontakt'}>
              Kontakt oss
            </OverflowMenuLink>
          </OverflowMenu>
        ) : (
          <>
            <TopNavigationItem
              active={location.pathname === '/omoss'}
              as={Link}
              to={'omoss'}
            >
              Hvem er vi?
            </TopNavigationItem>
            <TopNavigationItem
              active={location.pathname === '/ressurser'}
              as={Link}
              to={'ressurser'}
            >
              Hva gjør vi?
            </TopNavigationItem>
            <TopNavigationItem
              active={location.pathname === '/aktuelt'}
              as={Link}
              to={'aktuelt'}
            >
              Aktuelt
            </TopNavigationItem>
            <TopNavigationItem
              active={location.pathname === '/kontakt'}
              as={Link}
              to={'kontakt'}
            >
              Kontakt oss
            </TopNavigationItem>
          </>
        )}
      </div>
    </header>
  );
}

export default Header;

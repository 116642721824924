import React from 'react';
import {
  Blockquote,
  Heading2,
  Link as EnturLink,
  Paragraph
} from '@entur/typography';
import { ReactComponent as PitchSvg } from '../../assets/decoration/pitch.svg';
import CustomSection from '../CustomSection/CustomSection';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

function Forside() {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <>
      <Helmet>
        <title>Samferdselsdata</title>
      </Helmet>
      <div className={styles.banner}>
        <div className={styles.bannerContent}>
          <Paragraph>
            Vi tilrettelegger for enklere datadeling i samferdselssektoren
          </Paragraph>
        </div>
      </div>
      <CustomSection primary={false}>
        <div className={styles.titleContainer}>
          <div>
            <Heading2>Tverrsektorielt datasamarbeid</Heading2>
            <Paragraph>
              Det tverrsektorielle datasamarbeidet jobber for økt deling og
              utnyttelse av data i samferdselssektoren, og gjennom dette
              realisere samarbeidets ambisjon om å:
            </Paragraph>
            <Blockquote className={styles.quote}>
              «Tilrettelegge for bedre og mer effektive tjenester og
              beslutningsgrunnlag for innbyggerne, transportvirksomheter og
              øvrige beslutningstagere i samfunnet»
            </Blockquote>
            <Paragraph>
              Optimalisert bruk og deling av data i samferdselssektoren kan gi
              store gevinster, både til transportvirksomhetene, de reisende og
              samfunnet for øvrig.
            </Paragraph>
            <Paragraph>
              <EnturLink as={Link} to={'/omoss'}>
                Les mer om oss her.
              </EnturLink>
            </Paragraph>
          </div>
          {!isMobileScreen && <PitchSvg />}
        </div>
      </CustomSection>
      <CustomSection>
        <Heading2>
          Du kan finne datasett på{' '}
          <EnturLink
            as={Link}
            to={'http://data.norge.no'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            data.norge.no
          </EnturLink>
        </Heading2>
        <Paragraph>
          Vi jobber kontinuerlig med å tilgjengeliggjøre mer data.
        </Paragraph>
      </CustomSection>
    </>
  );
}

export default Forside;

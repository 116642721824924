import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  primary?: boolean;
  id?: string;
  className?: string;
  children: ReactNode;
}

function CustomSection({ primary = true, id, className, children }: Props) {
  return (
    <div
      className={`${styles.textSection} ${className} ${
        primary ? styles.primary : styles.secondary
      }`}
      id={id}
    >
      <div className={styles.textSectionContent}>{children}</div>
    </div>
  );
}

export default CustomSection;

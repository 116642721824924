import React from 'react';
import CustomSection from '../CustomSection/CustomSection';
import {
  Heading1,
  Heading3,
  LeadParagraph,
  Link,
  Paragraph
} from '@entur/typography';
import { Link as RRDLink } from 'react-router-dom';
import CardSection from '../CardSection/CardSection';
import { Helmet } from 'react-helmet';
import Samarbeidsstruktur from '../Samarbeidsstruktur/Samarbeidsstruktur';
import { ReactComponent as FokusSvg } from '../../assets/decoration/fokus.svg';
import { ReactComponent as DigdirSvg } from '../../assets/decoration/pilotmeddigdir.svg';
import { ReactComponent as MobilitetSvg } from '../../assets/decoration/mobilitetsdata.svg';
import { ReactComponent as DatarepSvg } from '../../assets/decoration/datarep.svg';
import { ReactComponent as CO2eSvg } from '../../assets/decoration/co2e.svg';
import styles from './styles.module.scss';
import { useMediaQuery } from 'react-responsive';

function AboutSide() {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 767px)' });

  const tekstlink = (to: string, tekst: string) => {
    return (
      <Link
        as={RRDLink}
        to={to}
        target={to.startsWith('http') ? '_blank' : '_self'}
      >
        {tekst}
      </Link>
    );
  };

  return (
    <>
      <Helmet>
        <title>Hva gjør vi? | Samferdselsdata</title>
      </Helmet>
      <CustomSection>
        <div className={styles.titleContainer}>
          <div>
            <Heading1>Hva gjør vi?</Heading1>
            <LeadParagraph>
              Overordnet er arbeidet i det tverrsektorielle datasamarbeidet delt
              i de tre arbeidsstrømmene Tekniske løsninger for datadeling,
              Bruksområder og Juridisk. I tillegg er det opprettet arenaer for
              erfaringsdeling for både tekniske og juridiske problemstillinger.
              Under kan du lese mer om noen av våre fokusområder.
            </LeadParagraph>
          </div>
          {!isMobileScreen && <FokusSvg />}
        </div>
        <Samarbeidsstruktur />
        <Heading3>Tekniske løsninger for datadeling</Heading3>
        <Paragraph>
          Etablere gode tekniske løsninger og løsningsmønstre som bidrar til
          enkel og sikker deling av data. Det inkluderer oppdagelse av data,
          tilgangsstyring og distribusjoner. Vi jobber med dette gjennom å
          videreutvikle nasjonale løsninger, for å tilrettelegge for at hele det
          offentlige Norge kan benytte løsningene som utarbeides. Etablere gode
          tekniske løsninger og løsningsmønstre som bidrar til enkel og sikker
          deling av data. Det inkluderer oppdagelse av data, tilgangsstyring og
          distribusjoner. Vi jobber med dette gjennom å videreutvikle nasjonale
          løsninger, for å tilrettelegge for at hele det offentlige Norge kan
          benytte løsningene som utarbeides.
        </Paragraph>
        <Heading3>Verdicase</Heading3>
        <Paragraph>
          Data fra samferdselssektoren kan brukes på flere ulike områder for å
          skape verdi for samfunnet og de reisende. I samarbeidet jobbes det med
          caser som kan vise verdiskaping ved bruk og deling av data. Dette
          arbeidet er også sentralt for å teste hypoteser, forslag og løsninger
          fra de andre arbeidsstrømmene.
        </Paragraph>
        <Paragraph>
          Samarbeidets sentrale rolle mellom virksomhetene gir muligheten til å
          identifisere og/eller drive initiativ der bruk og deling av data kan
          skape stor verdi. Det gir også mulighet til å identifisere behov som
          går på tvers av virksomhetene, og dermed skapes det muligheter for
          felles initiativ.
        </Paragraph>
        <Heading3>Juridisk</Heading3>
        <Paragraph>
          Håndtere og finne løsninger på juridiske barrierer som gjør det
          krevende å dele og bruke data. Samarbeidet skal være en pådriver for
          likere tolkning av relevant regelverk og helhetlig regelverksutvikling
          i sektoren, og utforske hvilke muligheter og begrensninger som kommer
          med nye direktiver og forordninger fra EU.
        </Paragraph>
      </CustomSection>
      <CardSection
        title={'Fokusområder'}
        subtitle={'Her finner du noen fokusområder vi jobber med'}
      />
      <CustomSection id={'digdir'} className={styles.leftContainer}>
        <div className={styles.container}>
          <div>
            <Heading1>Pilot med Digdir</Heading1>
            <Paragraph>
              Vi utvider og tilpasser de eksisterende løsningene for å lage en
              nasjonal løype for datadeling.
            </Paragraph>
            <Paragraph>
              Digitaliseringsdirektoratet forvalter allerede en rekke nasjonale
              fellestjenester og videreutvikling av disse til datadelingsformål
              vil kunne skape varige verdier på tvers av sektorer. Der det ikke
              finnes en eksisterende nasjonal løsning kan samarbeidet bidra med
              pilotering, behov og innsikt for å avdekke nye muligheter.
            </Paragraph>
            <Paragraph>
              Teknisk strøm i prosjektet samarbeider tett med Digdir. Vi ser på
              hvordan man kan knytte sammen de eksisterende nasjonale løsningene
              som felles datakatalog, og tilgangsstyring gjennom Maskinporten og
              Ansattporten for å forenkle tilgang til beskyttede data. Her
              bidrar prosjektet på flere områder og jobber utforskende for å
              utnytte eksisterende løsninger.
            </Paragraph>
            <Paragraph>
              Arbeidet vårt med tilgangsstyring er dokumentert hos Digdir.
              Produktene det gjelder er henholdsvis utvidelser av{' '}
              {tekstlink(
                'https://docs.digdir.no/docs/idporten/oidc/ansattporten_allmennsky.html',
                'Ansattporten'
              )}{' '}
              for ansattes pålogging og{' '}
              {tekstlink(
                'https://docs.digdir.no/docs/Maskinporten/maskinporten_skyporten',
                'Maskinporten'
              )}{' '}
              for maskinpålogging knyttet til organisasjon.
            </Paragraph>
            <Paragraph>
              {tekstlink(
                'https://docs.google.com/document/d/14OwjpVy7j16aqwolzFWPGm7mViayeEyPCJWIDXLJT8M/edit?usp=sharing',
                'Her'
              )}{' '}
              publiserer vi også løpende informasjon om piloten.
            </Paragraph>
            <Paragraph>
              {tekstlink('/kontakt', 'Ta kontakt')} om du vil være med å teste
              eller har innspill.
            </Paragraph>
          </div>
          <DigdirSvg />
        </div>
      </CustomSection>
      <CustomSection
        id={'mobilitetsdata'}
        primary={false}
        className={styles.rightContainer}
      >
        <div className={styles.container}>
          <MobilitetSvg />
          <div>
            <Heading1>Reisemønster på tvers</Heading1>
            <Paragraph>
              Dypere forståelse av reisemønsteret i Norge er en forutsetning for
              å kunne realisere betydelig samfunnsverdi. Dette omfatter
              optimalisering av transportnettverket, kapasitetsplanlegging av
              infrastruktur og hyppigere utvikling av tjenester med høy
              kvalitet.
            </Paragraph>
            <Paragraph>
              For å realisere denne verdien jobber samarbeidet for å
              tilgjengeliggjøre data om reisemønster og transportflyt, på tvers
              av transportmodaler. Ved å samarbeide aktivt med virksomhetene i
              samferdselssektoren for å utveksle prioriterte data, er målet å
              skape varige og effektive systemer for datadeling.
            </Paragraph>
          </div>
        </div>
      </CustomSection>
      <CustomSection id={'datarep'} className={styles.leftContainer}>
        <div className={styles.container}>
          <div>
            <Heading1>Forenklet onboarding</Heading1>
            <Paragraph>
              Onboarding for konsumenter i Maskinporten har fått et ekspressløp
              på{' '}
              {tekstlink(
                'https://onboarding.maskinporten.no',
                'https://onboarding.maskinporten.no'
              )}
            </Paragraph>
            <Paragraph>
              Maskinporten kan brukes både til sikring av apier og datadeling,
              men terskelen for å ta det i bruk fra konsumentsiden var høyere
              enn ønsket, særlig for førstegangskonsumenter. Ved å ta ned denne
              terskelen er veien til datadeling med nasjonal infrastruktur
              forenklet.
            </Paragraph>
            <Paragraph>
              Sammen med Digdir har vi utviklet en pilot som er nå åpen for alle
              virksomheter som skal ta i bruk Maskinporten. Løsningen har tatt
              utgangspunkt i førstegangskonsumenter i Maskinporten og forenkler
              onboardingen som ny kunde i Digdir og prosessen for å opprette en
              ny integrasjon til en tilgangsstyring api eller datasett.
              Forvaltning og eierskap er hos Digdir og kan brukes i alle
              sektorer.
            </Paragraph>
          </div>
          <DatarepSvg />
        </div>
      </CustomSection>
      <CustomSection
        id={'co2e'}
        primary={false}
        className={styles.rightContainer}
      >
        <div className={styles.container}>
          <CO2eSvg />
          <div>
            <Heading1>Felles utslippsberegning for samferdsel</Heading1>
            <Paragraph>
              For å oppnå FNs klimamål må vi reise mer miljøvennlig, velge
              bærekraftige løsninger og utvikle effektive tjenester som bidrar
              til at vi tar grønnere@ valg i hverdagen. Ved å gi reisende
              innsikt i egne reisevaner, sikter vi mot å øke bevisstheten rundt
              miljøvennlige valg.
            </Paragraph>
            <Paragraph>
              En felles standardisert løsning for utslippsberegning vil redusere
              utviklings- og administrasjonskostnader i næringslivet. Det vil
              utvikles tjenester som peker på effektive klimatiltak, forenkler
              bærekraftsrapportering og standardiserer krav i anbudsprosesser.
            </Paragraph>
            <Paragraph>
              Samarbeidet samler samferdselsaktører for utvikling av en
              standardisert metodikk for utslippsberegninger, erfaringsdeling og
              videreutvikling av løsninger basert på sektorens behov. Metodikken
              benyttes i felles beregningsmodell utviklet av Sintef som du kan
              lese mer om her.
            </Paragraph>
            <Paragraph>
              Du kan lese mer om tjenesten{' '}
              {tekstlink('https://miljo.entur.org', 'her')} eller få tilgang til
              dataproduktet {tekstlink('https://data.entur.no', 'her')}.
            </Paragraph>
          </div>
        </div>
      </CustomSection>
    </>
  );
}

export default AboutSide;

import React from 'react';
import {
  ClockIcon,
  ExternalIcon,
  MapPinIcon,
  OpenedLockIcon,
  ClosedLockIcon,
  PurposeIcon, LinkIcon
} from '@entur/icons';
import styles from './styles.module.scss';
import moment from "moment";
import 'moment/locale/nb';

interface Props {
  to?: string;
  title: string;
  date: string;
  time?: string;
  place?: string;
  open?: boolean;
  target?: string;
  desc: string;
  external?: boolean;
}

function AktueltBoks(props: Props) {

  const dateString = () => {
    const date = moment(props.date, "DD.MM.YYYY");
    date.locale("nb")
    return date.format("DD.MMMM YY");
  }

  const content = (
    <div className={styles.content}>
      <div>
          <span className={styles.title}>
            {props.title}
            {props.to && props.external && <ExternalIcon size={20} />}
            {props.to && !props.external && <LinkIcon size={20} />}
          </span>
        <div className={styles.desc}>
          {props.desc}
        </div>
      </div>
      <div className={styles.iconLinje}>
        {props.date && (
          <div className={styles.metafield}>
            <ClockIcon />
            <span className={styles.metainfo}>{props.time ? `${props.time}, ` : ""}{dateString()}</span>
          </div>
        )}
        {props.place && (
          <div className={styles.metafield}>
            <MapPinIcon />
            <span className={styles.metainfo}>{props.place}</span>
          </div>
        )}
        {props.open &&
            <div className={styles.metafield}>
              {props.open ? <OpenedLockIcon /> : <ClosedLockIcon />}
                <span className={styles.metainfo}>
                {props.open ? 'Åpent' : 'Lukket'}
              </span>
            </div>
        }
        {props.target && (
          <div className={styles.metafield}>
            <PurposeIcon />
            <span className={styles.metainfo}>{props.target}</span>
          </div>
        )}
      </div>
    </div>
  );

    return props.to ? (
      <a
        href={props.to}
        target="_blank"
        rel="noreferrer"
        className={styles.container}
      >
        {content}
      </a>
    ) : (
      <div className={styles.container}>
        {content}
      </div>
    )
}

export default AktueltBoks;
